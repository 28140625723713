@charset "utf-8";
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat|Nunito&display=swap");
@font-face {
  font-family: "BebasNeue";
  // src: url('webfont.eot'); /* IE9 Compat Modes */
  src: url("../assets/fonts/bebas-neue/BebasNeue-Regular.woff") format("woff"); /* Pretty Modern Browsers */
}
//Set your brand colors
// $purple: #8A4D76;
// $pink: #FA7C91;
// $brown: #757763;
// $beige-light: #D0D1CD;
// $beige-lighter: #EFF0EB;
$mustard: #021d44;
$mustard-2: #f3ba42;
$green: #1e7349;
// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
// $grey-dark: $brown;
// $grey-light: $beige-light;
$primary: $mustard-2;
$warning: white;
$mg-teal: #0d6d6e;
// $link: $pink;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// // Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

.header {
  font-size: 450%;
}

.is-teal {
  color: #0d6d6e;
}

.notification {
  cursor: pointer;
}

.has-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.is-underlined {
  padding-bottom: 0px;
  border-bottom: 7px solid $mustard-2;
}

.is-circular {
  border-radius: 50%;
  width: 13em;
}

.is-circular-v2 {
  border-radius: 50%;
  width: 10em;
}

html {
  scroll-behavior: smooth;
}

.is-disappearing {
  opacity: 1;
  transition: opacity 1s;
}

.is-disappearing:hover {
  opacity: 0;
}

.image-container {
  position: relative;
}

.over {
  position: absolute;
  z-index: 10;
}

.under {
  position: relative;
  z-index: 9;
}

.is-padded {
  padding-left: 10%;
  padding-right: 10%;
}

$navbar-item-hover-color: hsl(0, 0%, 29%);
$notification-radius: 10px;

.is-navbar-item {
  color: hsl(0, 0%, 29%);
  transition: color 1s, border-bottom 1s;
}

.is-navbar-item:hover {
  color: gray;
  border-bottom: 5px solid $green;
}

@import "../../node_modules/bulma/bulma.sass";
@import "../../node_modules/bulma-timeline/";
